import BaselineStyles from '@iabbb/shared/styles/BaselineStyles';
import { FeatureFlagProvider } from '@iabbb/context/FeatureFlag';
import { ReduxProvider } from '@iabbb/context/Redux';
import { NavigatorProvider } from '@iabbb/context/Navigator';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import createRootReducer from '@iabbb/utils/routing/createRootReducer';
import ErrorFallback from '@iabbb/shared/components/General/ErrorFallback';
import { ContactDialogProvider, DialogContactForm } from '@iabbb/shared/components/General/EmailAddress';
import historyListener from '@iabbb/utils/browser/historyListener';
import Route from '@iabbb/utils/routing/Route';
import routes from './routes';

import '@iabbb/bds/custom-props.css';
import '@iabbb/bds/cube.css';

App.propTypes = {
  featureFlagState: PropTypes.object, // can be null, when request fails/tracking is disabled
  initialState: PropTypes.object.isRequired,
  href: PropTypes.string.isRequired,
};

function App({ featureFlagState, initialState, href }) {
  return (
    <>
      <BaselineStyles />
      <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[href]}>
        <FeatureFlagProvider value={featureFlagState}>
          <NavigatorProvider href={href} listener={historyListener}>
            <ReduxProvider initialState={initialState} createRootReducer={createRootReducer}>
              <ContactDialogProvider>
                <DialogContactForm />
                <Route href={href} routes={routes} />
              </ContactDialogProvider>
            </ReduxProvider>
          </NavigatorProvider>
        </FeatureFlagProvider>
      </ErrorBoundary>
    </>
  );
}

export default Sentry.withProfiler(App);
