import BusinessProfileAccreditation from '@iabbb/businessprofile/pages/profileAccreditation';
import BbbReportsOn from '@iabbb/businessprofile/pages/profileBbbReportsOn';
import BusinessProfileComplaints from '@iabbb/businessprofile/pages/profileComplaints';
import BusinessProfileBusiness from '@iabbb/businessprofile/pages/profileDetails';
import EmailThisBusiness from '@iabbb/businessprofile/pages/profileEmailThisBusiness';
import BusinessProfileMoreInfo from '@iabbb/businessprofile/pages/profileMoreInfo';
import BusinessProfile from '@iabbb/businessprofile/pages/profileOverview';
import BusinessProfileRating from '@iabbb/businessprofile/pages/profileRating';
import BusinessProfileReviewExpiration from '@iabbb/businessprofile/pages/profileReviewExpiration';
import BusinessProfileReviews from '@iabbb/businessprofile/pages/profileReviews';
import BusinessProfileReviewsContactForm from '@iabbb/businessprofile/pages/profileReviewsContactForm';
import BusinessProfileLeaveReviewForm from '@iabbb/businessprofile/pages/profileReviewsLeaveReviewForm';
import BusinessProfileReviewsVerifySmsForm from '@iabbb/businessprofile/pages/profileReviewsVerifySmsForm';
import BusinessProfileReviewThankYou from '@iabbb/businessprofile/pages/profileReviewThankYou';
import { US_CA_COUNTRY_URL_SEGMENT } from '@iabbb/utils/routing/constants';

const routes = [
  {
    path: [`/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId`],
    id: 'businessprofile',
    component: BusinessProfile,
  },
  {
    path: [
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/addressId/:addressId`,
    ],
    id: 'localbusinessprofile',
    component: BusinessProfile,
  },
  {
    path: [`/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/details`],
    id: 'businessprofile_business',
    component: BusinessProfileBusiness,
  },
  {
    path: [
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/customer-reviews`,
    ],
    id: 'businessprofile_reviews',
    component: BusinessProfileReviews,
  },
  {
    path: [
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/email-this-business`,
    ],
    id: 'businessProfile_emailthisbusiness',
    component: EmailThisBusiness,
  },
  {
    path: [
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/customer-reviews/leave-a-review`,
    ],
    id: 'businessprofile_reviews_leave-a-review',
    component: BusinessProfileLeaveReviewForm,
  },
  {
    path: [
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/customer-reviews/contact-form`,
    ],
    id: 'businessprofile_reviews_contact',
    component: BusinessProfileReviewsContactForm,
  },
  {
    path: [
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/customer-reviews/verify-sms`,
    ],
    id: 'businessprofile_reviews_verifysms',
    component: BusinessProfileReviewsVerifySmsForm,
  },
  {
    path: [`/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/complaints`],
    id: 'businessprofile_complaints',
    component: BusinessProfileComplaints,
  },
  {
    path: [
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/overview-of-bbb-ratings`,
    ],
    id: 'businessprofile_rating',
    component: BusinessProfileRating,
  },
  {
    path: [
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/accreditation-information`,
    ],
    id: 'businessprofile_accreditation',
    component: BusinessProfileAccreditation,
  },
  {
    path: [
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/submit-review-thank-you`,
    ],
    id: 'businessprofile_reviewthankyou',
    component: BusinessProfileReviewThankYou,
  },
  {
    path: [
      `/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/submit-review-expiration`,
    ],
    id: 'businessprofile_reviewexpiration',
    component: BusinessProfileReviewExpiration,
  },
  {
    path: [`/${US_CA_COUNTRY_URL_SEGMENT}/:type/:city/profile/:businessInfo`],
    id: 'bp_type_city_profile_businessinfo',
    component: BusinessProfileBusiness,
  },
  {
    path: [`/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/bbb-reports-on`],
    id: 'businessprofile_bbbreportson',
    component: BbbReportsOn,
  },
  {
    path: [`/${US_CA_COUNTRY_URL_SEGMENT}/:state/:city/profile/:category/:name-:bbbId-:businessId/more-info`],
    id: 'businessprofile_bbbreportson',
    component: BusinessProfileMoreInfo,
  },
];

export default routes;
