import { CacheProvider } from '@emotion/react';
import { get as getInitialState } from '@iabbb/context/Redux/initialStateUtils';
import createEmotionCache from '@iabbb/shared/styles/createEmotionCache';
import { initSentry } from '@iabbb/utils/sentry';
import * as ReactDOMClient from 'react-dom/client';

import 'wicg-inert';
// 👆 Typically, we follow a progressive enhancement (no polyfill) philosophy.
// However, the behavior provided by the `inert` attribute is tricky to build out by hand;
// it is important that it is done right so that dialog elements can be interacted with accessibly.

import App from './App';

const cache = createEmotionCache();

initSentry();

// test if webpack is in development mode
// biome-ignore lint/correctness/noUndeclaredVariables: webpack will set this
if (__DEV__) {
  window.__DEV__ = true;
}

const root = document.getElementById('root');
const initialState = getInitialState();

function AppWithProviders() {
  return (
    <CacheProvider value={cache}>
      <App
        featureFlagState={window.__FEATURE_FLAG_STATE__}
        initialState={initialState}
        href={window.location.href}
      />
    </CacheProvider>
  );
}

if (!root.childElementCount) {
  // If markup is not present, we need to create it.
  ReactDOMClient.createRoot(root).render(<AppWithProviders />);
} else {
  ReactDOMClient.hydrateRoot(root, <AppWithProviders />);
}
