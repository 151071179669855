import IconEmail from '@iabbb/icons/Email';
import Button from '@iabbb/bds-react/Button';
import clsx from 'clsx';
import React, { useMemo } from 'react';

import { useContactDialog } from './DialogContactForm/context';

export type EmailAddressProps = {
  buttonProps?: React.ComponentPropsWithoutRef<'button'>;
  DialogProps?: {
    entityId?: string;
    entityType?: string;
    headingText?: string;
    thankYouText?: string;
  };
  email: string;
  emailLabel?: string;
  showIcon?: boolean;
  showPR?: boolean;
};

function EmailAddress(props: EmailAddressProps & React.ComponentPropsWithoutRef<'span'>) {
  const dialog = useContactDialog();

  const {
    buttonProps = {},
    email,
    showPR,
    emailLabel,
    showIcon = true,
    DialogProps = {},
    ...additionalProps
  } = props;

  const _emailLabel = useMemo(() => {
    if (emailLabel) {
      return emailLabel;
    }

    if (DialogProps.entityType) {
      return `Email this ${DialogProps.entityType}`;
    }

    // Otherwise, return a default email label
    return showPR ? 'Email BBB PR' : 'Email this BBB';
  }, [DialogProps.entityType, emailLabel, showPR]);

  const handleEmailButtonClick = () => {
    dialog.dispatch({
      type: 'open',
      props: {
        toEmail: email,
        ...DialogProps,
      },
    });
  };

  if (!email) {
    return null;
  }

  return (
    <Button
      onClick={handleEmailButtonClick}
      className={clsx({ 'with-icon': showIcon }, buttonProps.className)}
      variant="unstyled"
      {...buttonProps}
    >
      {showIcon && <IconEmail />}
      <span {...additionalProps}>{_emailLabel}</span>
    </Button>
  );
}

export default React.memo(EmailAddress);
